<!-- =========================================================================================
  File Name: TheFooter.vue
  Description: Footer component
  Component Name: TheFooter
========================================================================================== -->


<template functional>
  <footer class="the-footer flex-wrap justify-between" :class="classes">
    <p>
      <span>Copyright &copy;</span>
      <span>{{ new Date().getFullYear() }}</span>
      <span class="hidden sm:inline-block">, All rights Reserved</span>
    </p>
  </footer>
</template>

<script>
export default {
  name: 'the-footer',
  props: {
    classes: {
      type: String,
    },
  },
}
</script>
