/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
==========================================================================================*/

export default [
  {
    url: '/',
    name: 'Outbound Dashboard',
    slug: 'home',
    icon: 'HomeIcon',
  },
  {
    url: '/members',
    name: 'Members',
    slug: 'member',
    icon: 'UserIcon',
  },
  {
    url: '/contact-us',
    name: 'Contact Us',
    slug: 'contact-us',
    icon: 'MailIcon',
  },
  {
    url: '/gift-sets-requests',
    name: 'Gift Sets Requests',
    slug: 'gift-sets',
    icon: 'GiftIcon',
  },
  {
    header: 'Admin',
    items: [
      {
        url: '/reports',
        name: 'Reports',
        slug: 'report',
        icon: 'FileTextIcon',
      },
      {
        url: '/users',
        name: 'Users',
        slug: 'user',
        icon: 'UsersIcon',
      },
      {
        url: '/tracking-topics',
        name: 'Tracking Topics',
        slug: 'tracking-topic',
        icon: 'MessageSquareIcon',
      },
      {
        url: '/tracking-reasons',
        name: 'Tracking Reasons',
        slug: 'tracking-reason',
        icon: 'MessageCircleIcon',
      },
      {
        url: '/sample-sets',
        name: 'Sample Sets',
        slug: 'sample-set',
        icon: 'GiftIcon',
      },
    ],
  },
]
